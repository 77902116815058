import React from 'react';

const PrivacyPolicy = () => {
  return (
    <section>
      <div className="flex flex-col items-center justify-center px-5 md:px-10">
        {/* Title Container */}
        <div className="flex h-auto min-w-[100vw] flex-col items-center justify-end py-6 md:h-64">
          <div className="flex flex-col items-center gap-y-4 py-5">
            <h1 className="text-3xl font-bold md:text-5xl">PRIVACY POLICY</h1>
            <p className="text-sm text-[#808080] sm:text-base">
              Last Updated as of May 10, 2024
            </p>
          </div>
        </div>
        {/* Content Container */}
        <div className="mx-auto w-full max-w-5xl py-12 md:py-16 lg:py-20">
          {/* Component */}
          <div className="flex flex-col items-center gap-y-14">
            <p className="max-w-3xl text-center text-sm sm:text-base">
              akmfinances is committed to protecting your privacy. This Privacy
              Policy describes how your personal information is collected, used,
              and shared when you visit or make a purchase from our website or
              utilize our services.
            </p>
            <div className="flex min-w-full flex-col gap-y-10">
              <div className="flex min-w-full py-4 border-b border-solid border-[#e2e2e2]">
                <h6 className="text-base font-bold">INFORMATION WE COLLECT</h6>
              </div>
              <div className="flex flex-col gap-y-10">
                <div className="flex min-w-full flex-col items-start gap-y-3">
                  <div className="flex flex-col items-start gap-y-3">
                    <p className="text-sm font-bold">PERSONAL INFORMATION</p>
                    <p className="text-sm">
                      We collect personal information, such as your name, email
                      address, and phone number, when you voluntarily provide it
                      to us. This information is used to communicate with you
                      and provide the services you request.
                    </p>
                  </div>
                  <div className="flex flex-col items-start gap-y-3">
                    <p className="text-sm font-bold">USAGE DATA</p>
                    <p className="text-sm">
                      We may also collect information about how you interact
                      with our website and services, including your IP address,
                      browser type, and operating system. This data is used to
                      analyze trends, administer the site, track user movements,
                      and gather demographic information for aggregate use.
                    </p>
                  </div>
                  <div className="flex flex-col items-start gap-y-3">
                    <p className="text-sm font-bold">COOKIES</p>
                    <p className="text-sm">
                      We use cookies to store information about your preferences
                      and to personalize the website experience. You can control
                      the use of cookies through your browser settings.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex min-w-full py-4 border-b border-solid border-[#e2e2e2]">
                <h6 className="text-base font-bold">
                  HOW WE USE YOUR INFORMATION
                </h6>
              </div>
              <p className="max-w-3xl text-center text-sm sm:text-base">
                We use the information we collect to provide and improve our
                services, communicate with you, and personalize your experience.
                We may also use your information to comply with legal
                obligations, resolve disputes, and enforce agreements.
              </p>
              <div className="flex min-w-full py-4 border-b border-solid border-[#e2e2e2]">
                <h6 className="text-base font-bold">
                  SHARING YOUR INFORMATION
                </h6>
              </div>
              <p className="max-w-3xl text-center text-sm sm:text-base">
                We may share your personal information with third-party service
                providers who assist us in delivering our services or operating
                our website. We may also share information in response to legal
                requests or to protect our rights and property.
              </p>
              <div className="flex min-w-full py-4 border-b border-solid border-[#e2e2e2]">
                <h6 className="text-base font-bold">YOUR CHOICES</h6>
              </div>
              <p className="max-w-3xl text-center text-sm sm:text-base">
                You have the right to access, update, and delete your personal
                information. You may also opt out of receiving promotional
                communications from us. Please contact us for assistance with
                any requests.
              </p>
              <div className="flex min-w-full py-4 border-b border-solid border-[#e2e2e2]">
                <h6 className="text-base font-bold">
                  CHANGES TO THIS PRIVACY POLICY
                </h6>
              </div>
              <p className="max-w-3xl text-center text-sm sm:text-base">
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or for other operational, legal, or
                regulatory reasons. We encourage you to review this Privacy
                Policy periodically for any updates.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;