import React from "react";
import styled, { keyframes } from "styled-components";

const slide = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
`;

const SliderContainer = styled.div`
  overflow: hidden;
  width: 100%;
`;

const SlideTrack = styled.div`
  display: flex;
  width: calc(50% * ${(props) => props.numImages});
  animation: ${slide} ${(props) => props.duration}s linear infinite;
`;

const Slide = styled.div`
  flex: auto;
  width: ${(props) => 100 / props.numImages}%;
  box-sizing: border-box;
  padding: 0 10px; // Adjust this for the space between images
  img {
    border-radius: 10px; // Adjust this for rounded corners if needed
  }
`;

const ImageSlider = ({ images, duration }) => {
  return (
    <SliderContainer>
      <SlideTrack numImages={images.length} duration={duration}>
        {images.concat(images).map((image, index) => (
          <Slide key={index} numImages={images.length}>
            <img src={image} width={200} height={200} alt={`Slide ${index}`} className="object-cover w-[300px] h-[200px] lg:h-[320px] lg:w-[320px]" />
          </Slide>
        ))}
      </SlideTrack>
    </SliderContainer>
  );
};

export default ImageSlider;
