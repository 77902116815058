import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { useParams, Link } from "react-router-dom";

export default function Checkout() {
  const [page, setPage] = useState(1);
  const [isScriptLoaded, setScriptLoaded] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [paymentMade, setPaymentMade] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [trxId, setTrxID] = useState("");
  const [orderId, setOrderId] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const { config, endpoint } = useAuth();

  const { quantity, color } = useParams();

  const handleModalClose = () => {
    console.log("Modal closed");
    alert("modalClosed");
    // Add any checks or cleanup logic here
  };

  // useEffect(() => {
  //   // Dynamically load the Klasha script
  //   const script = document.createElement("script");
  //   script.src = "https://js.klasha.com/pay.js";
  //   script.type = "text/javascript";
  //   script.async = true;
  //   script.onload = () => setScriptLoaded(true); // Set the state to true once the script is loaded
  //   document.body.appendChild(script);

  //   // Cleanup script on component unmount
  //   return () => {};
  // }, []);

  async function paymentSuccess() {
    window.fbq("track", "Purchase", { currency: "USD", value: 87.2 });

    try {
      setSubmitLoading(true);
      const req = await fetch(`${endpoint}payment-success`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          orderId,
        }),
      });

      if (req.status === 200) {
        setPage(2);
      }
    } catch (err) {
      alert("An error occured, please try again");
      setSubmitLoading(false);
    } finally {
      setSubmitLoading(false);
    }
  }

  function SquadPay(trfId) {
    setSubmitLoading(false);
    const squadInstance = new window.squad({
      onClose: () => console.log(""),
      onLoad: () => console.log(""),
      onSuccess: () => setPaymentMade(true),
      key: "pk_ee0cd74d5b82d7f7ed164ab67f68ceaf7804687d",
      //Change key (test_pk_sample-public-key-1) to the key on your Squad Dashboard
      email: email,
      amount: 1.09 * config.product_fee_eur * 100,
      //Enter amount in Naira or Dollar (Base value Kobo/cent already multiplied by 100)
      currency_code: "USD",
      pass_charge: true,
      transaction_ref: trfId ? trfId : trxId,
    });
    squadInstance.setup();
    squadInstance.open();
    window.fbq("track", "InitiateCheckout", {
      value: "USD",
      currency: 87.2,
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (trxId) {
      SquadPay();
      return;
    }

    setSubmitLoading(true);
    try {
      const req = await fetch(`${endpoint}add-to-cart`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name,
          email,
          phoneNumber,
          address,
          state,
          city,
          quantity,
          color,
          postalCode,
        }),
      });
      const reqData = await req.json();

      if (req.status === 200) {
        setTrxID(reqData[0]);
        setOrderId(reqData[1]);
        SquadPay(reqData[0]);
      }
    } catch (err) {
      alert("An error occured, please try again");
      setSubmitLoading(false);
      console.log(err);
      console.warn("Error");
    }
  };

  const payWithKlasha = async () => {
    // const callWhenDone = (data) => {
    //   // setPage(2)
    // };
    // const kit = {
    //   currency: "USD",
    //   phone_number: phoneNumber,
    //   email: email,
    //   fullname: name,
    //   tx_ref: trxId,
    //   callBack: callWhenDone,
    // };
    // // Assuming that KlashaClient is available globally from the script
    // const client = new window.KlashaClient(
    //   "XE8s/fp5gznV0GMwJ22Zs/wfqb729qezubddem0fQyK6RSiVMNYuFn5uKa0lKQvMuZVtsaPqpEvdozG3MtXBqU349SiCOtILMSlNhzSS+uc=", // Replace with your merchant key
    //   "129492", // Replace with your business ID
    //   1.09 * config.product_fee_eur * quantity, // Replace with the amount
    //   "ktest", // Replace with the mode (e.g., "ktest" for test mode)
    //   "https://ce0e-185-183-34-32.ngrok-free.app/payment-made", // Replace with your callback URL
    //   "USD", // Replace with your currency
    //   "USD", // Replace with your source currency
    //   kit, // Payment configuration object
    //   true // true for test mode, false for live mode
    // );
    // if (client.init) {
    //   await client.init();
    //   setTimeout(() => {
    //     setSubmitLoading(false);
    //   }, 40000);
    //   window.addEventListener("message", (event) => {
    //     if (event.data === "close") {
    //     }
    //   });
    //   // Assuming modal close detection by clicking outside the modal
    //   document.addEventListener("click", (event) => {
    //     const modalElement = document.getElementById("myModal");
    //     if (modalElement && event.target === modalElement) {
    //     }
    //   });
    // } else {
    //   // alert("KlashaClient is not initialized correctly");
    //   setSubmitLoading(false);
    // }
  };

  // useEffect(() => {
  //   setInterval(async () => {
  //     if (orderId) {
  //       const req = await fetch(`${endpoint}get-order-status/${orderId}`);

  //       if (req.status === 200) {
  //         setPage(2);
  //       }
  //     }
  //   }, 30000);

  //   return () => {};
  // }, [trxId]);

  useEffect(() => {
    if (paymentMade) {
      paymentSuccess();
    }

    return () => {};
  }, [paymentMade]);

  if (page === 1) {
    return (
      <div>
        <div class="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32">
          <div class="px-4 pt-8">
            <p class="text-xl font-medium">Uitchecken</p>
            <p class="text-gray-400">
              Controleer uw artikelen. En selecteer een geschikte
              verzendmethode.
            </p>
            <div class="mt-8 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6">
              <div class="flex flex-col rounded-lg bg-white sm:flex-row">
                <img
                  class="m-2 h-24 w-28 rounded-md border object-cover object-center"
                  src={
                    color === "pink"
                      ? "https://nostalgixdigital.com/cdn/shop/files/DesignohneTitel_7.png?v=1720888940&width=128"
                      : "https://nostalgixdigital.com/cdn/shop/files/DesignohneTitel_8.png?v=1720888939&width=80"
                  }
                  alt=""
                />
                <div class="flex w-full flex-col px-4 py-4">
                  <span class="font-semibold">
                    AmsterZoom™ Pro digitale camera
                  </span>
                  <span class="float-right text-gray-400">x{quantity}</span>
                  <p class="text-lg font-bold">
                    €{config.product_fee_eur * quantity}
                  </p>
                </div>
              </div>
            </div>

            <p class="mt-8 text-lg font-medium">Verzendmethoden</p>
            <form class="mt-5 grid gap-6">
              <div class="relative">
                <input
                  class="peer hidden"
                  id="radio_1"
                  type="radio"
                  name="radio"
                  checked
                />
                <span class="peer-checked:border-gray-700 absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white"></span>
                <label
                  class="peer-checked:border-2 peer-checked:border-gray-700 peer-checked:bg-gray-50 flex cursor-pointer select-none rounded-lg border border-gray-300 p-4"
                  for="radio_1"
                >
                  <img
                    class="w-14 object-contain"
                    src="/images/naorrAeygcJzX0SyNI4Y0.png"
                    alt=""
                  />
                  <div class="ml-5">
                    <span class="mt-2 font-semibold">Geen verzendkosten</span>
                    <p class="text-slate-500 text-sm leading-6">
                      Levering: 7 - 12 dagen
                    </p>
                  </div>
                </label>
              </div>
            </form>
          </div>
          <div class="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0">
            <p class="text-xl font-medium">Verzendgegevens</p>
            <p class="text-gray-400">
              Rond uw bestelling af door uw verzendgegevens op te geven.
            </p>
            <form onSubmit={handleSubmit}>
              <div class="">
                <label
                  for="card-holder"
                  class="mt-4 mb-2 block text-sm font-medium"
                >
                  Voor-en achternaam
                </label>
                <div class="relative">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    name="card-holder"
                    required
                    class="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-black focus:ring-black"
                    placeholder=""
                  />
                </div>

                <label for="email" class="mt-4 mb-2 block text-sm font-medium">
                  Email
                </label>
                <div class="relative">
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    name="email"
                    class="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-black focus:ring-black"
                    placeholder=""
                  />
                </div>
                <label for="email" class="mt-4 mb-2 block text-sm font-medium">
                  Telefoonnummer
                </label>
                <div class="relative">
                  <input
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    required
                    class="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-black focus:ring-black"
                    placeholder=""
                  />
                </div>
                <label
                  for="card-holder"
                  class="mt-4 mb-2 block text-sm font-medium"
                >
                  Volledig adres
                </label>
                <div class="relative">
                  <input
                    type="text"
                    name="card-holder"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                    class="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-black focus:ring-black"
                    placeholder=""
                  />
                  <div class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3"></div>
                </div>

                <label
                  for="billing-address"
                  class="mt-4 mb-2 block text-sm font-medium"
                >
                  Stad en provincie
                </label>
                <div class="flex flex-col sm:flex-row">
                  <div class="relative mb-[20px] md:mb-[0px] flex-shrink-0 sm:w-7/12">
                    <input
                      type="text"
                      id="billing-address"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      name="billing-address"
                      required
                      class="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-black focus:ring-black"
                      placeholder="Stad"
                    />
                    <div class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3"></div>
                  </div>
                  <select
                    type="text"
                    name="billing-state"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    required
                    class="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-black focus:ring-black"
                  >
                    <option value="State">Province</option>
                    <option value="Drenthe">Drenthe</option>
                    <option value="Flevoland">Flevoland</option>
                    <option value="Friesland">Friesland</option>
                    <option value="Gelderland">Gelderland</option>
                    <option value="Groningen">Groningen</option>
                    <option value="Limburg">Limburg</option>
                    <option value="North Brabant">North Brabant</option>
                    <option value="North Holland">North Holland</option>
                    <option value="Overijssel">Overijssel</option>
                    <option value="South Holland">South Holland</option>
                    <option value="Utrecht">Utrecht</option>
                    <option value="Zeeland">Zeeland</option>
                  </select>
                </div>
                <label
                  for="card-holder"
                  class="mt-4 mb-2 block text-sm font-medium"
                >
                  Postcode
                </label>
                <div class="relative">
                  <input
                    type="text"
                    name="card-holder"
                    value={postalCode}
                    required
                    onChange={(e) => setPostalCode(e.target.value)}
                    class="w-full rounded-md border border-gray-200 px-4 py-3 text-sm uppercase shadow-sm outline-none focus:z-10 focus:border-black focus:ring-black"
                    placeholder=""
                  />
                </div>

                <div class="mt-6 border-t border-b py-2">
                  <div class="flex items-center justify-between">
                    <p class="text-sm font-medium text-gray-900">Subtotaal</p>
                    <p class="font-semibold text-gray-900">
                      €{config.product_fee_eur * quantity}
                    </p>
                  </div>
                  <div class="flex items-center justify-between">
                    <p class="text-sm font-medium text-gray-900">Verzenden</p>
                    <p class="font-semibold text-gray-900">€0.00</p>
                  </div>
                </div>
                <div class="mt-6 flex items-center justify-between">
                  <p class="text-sm font-medium text-gray-900">Totaal (USD)</p>
                  <p class="text-2xl font-semibold text-gray-900">
                    ${1.09 * config.product_fee_eur * quantity}
                  </p>
                </div>
                <div class="mt-6 flex items-center justify-between">
                  <p class="text-sm font-medium text-gray-900">Totaal (EUR)</p>
                  <p class="text-2xl font-semibold text-gray-900">
                    €{config.product_fee_eur * quantity}
                  </p>
                </div>
              </div>
              <button
                type="submit"
                disabled={submitLoading}
                class="mt-4 mb-8 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white"
              >
                {submitLoading && (
                  <svg
                    aria-hidden="true"
                    role="status"
                    class="inline w-4 h-4 me-3 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                )}
                {submitLoading ? "Loading..." : "Maak bestelling af"}
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div class="bg-gray-100 pt-[120px] h-screen">
        <div class="bg-white w-[80%] p-6 my-auto mx-auto">
          <svg
            viewBox="0 0 24 24"
            class="text-green-600 w-16 h-16 mx-auto my-6"
          >
            <path
              fill="currentColor"
              d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
            ></path>
          </svg>
          <div class="text-center">
            <h3 class="md:text-2xl text-base text-gray-900 font-semibold text-center">
              #{orderId} Betaling ontvangen!
            </h3>
            <p class="text-gray-600 my-2">
              Bedankt voor het voltooien van uw betaling. Uw bestelling wordt
              verwerkt zo spoedig mogelijk
            </p>
            <p>Een fijne dag verder!</p>
            <div class="py-10 text-center">
              <Link
                to="/"
                class="px-12 bg-black cursor-pointer text-white font-semibold py-3"
              >
                GA TERUG
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
