import React from 'react'
import { useLocation, Link } from 'react-router-dom';
import Logo from "../../assets/newLogo1.png";


export default function Footer() {
    const location = useLocation();

    if (location.pathname.includes("/checkout")) return null;
    
  return (
    <footer class="bg-gray-50">
      <div class="mx-auto grid max-w-screen-xl gap-y-8 gap-x-12 px-4 py-10 md:grid-cols-2 xl:grid-cols-4 xl:px-10">
        <div class="max-w-sm">
          <div class="mb-6 flex h-12 items-center space-x-2">
            <img src={Logo} class="w-28 h-auto" />
          </div>
          <div class="text-gray-500"></div>
        </div>
        <div class="">
          <div class="mt-4 mb-2 font-medium xl:mb-4">Contact</div>
          <div class="text-gray-500">
            info@amsterzoom.nl <br />
            45 Herengracht, <br />
            Amsterdam
          </div>
        </div>
        <div class="">
          <div class="mt-4 mb-2 font-medium xl:mb-4">Hulp</div>
          <nav aria-label="Footer Navigation" class="text-gray-500">
            <ul class="space-y-3">
              <li>
                <Link
                  to="/tracking"
                  class="hover:text-black hover:underline"
                  href="#"
                >
                  Verzending volgen
                </Link>
              </li>
              <li>
                <Link to="/" class="hover:text-black hover:underline" href="#">
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div class="">
          <div class="mt-4 mb-2 font-medium xl:mb-4">Legaal</div>
          <nav aria-label="Footer Navigation" class="text-gray-500">
            <ul class="space-y-3">
              <li>
                <Link
                  to="/refund-policy"
                  class="hover:text-black hover:underline"
                  href="#"
                >
                  Terugbetalingsbeleid
                </Link>
              </li>
              <li>
                <Link
                  to="/shipping-policy"
                  class="hover:text-black hover:underline"
                  href="#"
                >
                  Verzendbeleid
                </Link>
              </li>
              <li>
                <Link
                  to="/terms"
                  class="hover:text-black hover:underline"
                  href="#"
                >
                  Algemene voorwaarden
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
}
