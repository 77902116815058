import React from "react";
import "../styles/LoadingStyle2.scss";
import Logo from "../../assets/newLogo1.png";
import ReactLoading from "react-loading";

export default function LoadingComponent() {
  return (
    <div className="loading-overlay1">
      <div className="loading-content1">
        <img src={Logo} className="loading-icon1" />
        <ReactLoading
          type="spinningBubbles"
          color={"black"}
          height={37}
          width={37}
        />
      </div>
    </div>
  );
}
