import React from "react";

export default function ShippingPolicy() {
  return (
    <section>
      <div className="flex flex-col items-center justify-center px-5 md:px-10">
        {/* Title Container */}
        <div className="flex h-auto min-w-[100vw] flex-col items-center justify-end py-6 md:h-64">
          <div className="flex flex-col items-center gap-y-4 py-5">
            <h1 className="text-3xl font-bold md:text-5xl">
              VERZENDINGSBELEID
            </h1>
            <p className="text-sm text-[#808080] sm:text-base">
              Laatst bijgewerkt op 16 juli 2024
            </p>
          </div>
        </div>
        {/* Content Container */}
        <div className="mx-auto w-full max-w-5xl py-12 md:py-16 lg:py-20">
          {/* Component */}
          <div className="flex flex-col items-center gap-y-14">
            <p className="max-w-3xl text-center text-sm sm:text-base">
              Bij Amsterzoom is ons doel om u de beste verzendopties te bieden,
              zodat een tijdige levering binnen Nederland wordt gegarandeerd.
            </p>
            <div className="flex min-w-full flex-col gap-y-10">
              <div className="flex min-w-full py-4 border-b border-solid border-[#e2e2e2]">
                <h6 className="text-base font-bold">
                  HOE LANG DUURT DE LEVERING?
                </h6>
              </div>
              <div className="flex flex-col gap-y-10">
                <div className="flex min-w-full flex-col items-start gap-y-6">
                  <div className="flex flex-col items-start gap-y-3">
                    <p className="text-sm">
                      De levertijd bestaat uit twee perioden samen:
                    </p>
                    <p className="text-sm">
                      1 <strong>Verwerkingstijd (1-2 werkdagen):</strong>
                      Orderbevestiging, maatwerk, kwaliteitscontrole, verpakking
                    </p>
                    <p className="text-sm">
                      2 <strong>Levertijd (7-12 werkdagen):</strong> De tijd
                      van ons verzendcentrum tot de leveringslocatie binnen
                      Nederland.
                    </p>
                  </div>
                  <div className="flex flex-col items-start gap-y-3">
                    <p className="text-sm">
                      We bieden het volgende verzendtarief aan:
                    </p>
                    <p className="text-sm">
                      1. Gratis standaard verzending (met tracking) voor €0,00
                    </p>
                    <p className="text-sm">
                      Houd er rekening mee dat een bestelling niet kan worden
                      bewerkt of geannuleerd voordat deze is geleverd.
                    </p>
                    <p className="text-sm">
                      Houd er rekening mee dat in geval van een verloren pakket,
                      wij u alleen een vervanging/terugbetaling kunnen aanbieden
                      als u hebt gekozen voor verzekerde verzending.
                    </p>
                    <p className="text-sm">
                      Alle bestellingen worden onmiddellijk verwerkt om een
                      tijdige levering te garanderen.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
