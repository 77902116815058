import React, { useReducer, createContext, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";

export const DataManagementContext = createContext();

const dataManagementReducer = (state, action) => {
  switch (action.type) {
    case "SITE_IS_READY": {
      return { ...state, isReady: true, user: action.payload };
    }
    case "LOGIN": {
      return { ...state, user: action.payload };
    }
    case "UPDATE_SETTINGS": {
      return { ...state, config: action.payload, isReady: true };
    }
    case "LOGOUT": {
      return { ...state, user: null };
    }
    case "USER_LOGOUT": {
      return { ...state, user: null };
    }
    case "UPDATE_USER": {
      return { ...state, user: action.payload };
    }
    case "UPDATE_REFCODE": {
      return { ...state, user: { ...state.user, refCode: action.payload } };
    }
    case "UPDATE_VISITOR_DATA": {
      return { ...state, visitorData: action.payload };
    }
    default:
      return state;
  }
};

export const DataManagementProvider = ({ children }) => {
  const [state, dispatch] = useReducer(dataManagementReducer, {
    maintenance: false,
    isReady: false,
    user: null,
    endpoint: "https://api.chatyourexcel.com/",
    primaryColor: "#82310c",
    config: {
      maintenance: false,
    },
    visitorData: null,
  });

  async function getSettings() {
    try {
      const req = await fetch(`${state.endpoint}get-config`);
      const reqData = await req.json();
      dispatch({ type: "UPDATE_SETTINGS", payload: reqData });
    } catch (err) {
      console.log(err);
      console.warn("Error");
    }
  }

  async function handleDispatch() {
    await getSettings();

    const userData = localStorage.getItem("@record")

    if (!userData) {
      try {
        const req = await fetch(`${state.endpoint}new-visitor`, {
          method: 'post', headers: {'Content-Type': 'application/json'}
        });

        if (req.status === 200) {
          localStorage.setItem('@record', JSON.stringify({
            item: 1
          }))
        }
      } catch (err) {
        console.log(err);
        console.warn("Error");
      }
    }
  }

  async function handleRefresh() {
    const user = await localStorage.getItem("token");
    const userReq = await fetch(`${state.endpoint}user-refresh`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user}`,
      },
    });
    const userData = await userReq.json();
    if (userReq.status === 200) {
      localStorage.setItem("token", userData);
      const decoded = jwt_decode(userData);
      dispatch({ type: "UPDATE_USER", payload: decoded });
    }
  }

  useEffect(() => {
    handleDispatch();
  }, []);

  return (
    <DataManagementContext.Provider
      value={{ ...state, dispatch, handleRefresh, getSettings }}
    >
      {children}
    </DataManagementContext.Provider>
  );
};
