import React from "react";

export default function RefundPolicy() {
  return (
    <section>
      <div className="flex flex-col items-center justify-center px-5 md:px-10">
        {/* Title Container */}
        <div className="flex h-auto min-w-[100vw] flex-col items-center justify-end py-6 md:h-64">
          <div className="flex flex-col items-center gap-y-4 py-5">
            <h1 className="text-3xl font-bold md:text-5xl">
              TERUGBETALINGSBELEID
            </h1>
            <p className="text-sm text-[#808080] sm:text-base">
              Laatst bijgewerkt op 16 juli 2024
            </p>
          </div>
        </div>
        {/* Content Container */}
        <div className="mx-auto w-full max-w-5xl py-12 md:py-16 lg:py-20">
          {/* Component */}
          <div className="flex flex-col items-center gap-y-14">
            <p className="max-w-3xl text-center text-sm sm:text-base">
              Houd er rekening mee dat een bestelling niet kan worden bewerkt of
              geannuleerd voordat deze is geleverd. Alle bestellingen worden
              onmiddellijk verwerkt om een tijdige levering te garanderen.
            </p>
            <div className="flex min-w-full flex-col gap-y-10">
              <div className="flex min-w-full py-4 border-b border-solid border-[#e2e2e2]">
                <h6 className="text-base font-bold">HERROEPINGSRECHT</h6>
              </div>
              <div className="flex flex-col gap-y-10">
                <div className="flex min-w-full flex-col items-start gap-y-6">
                  <div className="flex flex-col items-start gap-y-3">
                    <p className="text-sm font-bold">
                      Wat moet de klant doen om een terugbetaling te krijgen?
                    </p>
                    <p className="text-sm">
                      Om een terugbetaling te ontvangen, moet u uw artikel
                      retourneren. Informeer ons per e-mail naar
                      info@amsterzoom.nl binnen 15 dagen na uw bestelling. Neem
                      contact op met onze klantenservice via info@amsterzoom.nl
                      om een terugbetaling te starten. We accepteren producten
                      die in hun originele verpakking zitten, ongebruikt of
                      slechts licht gebruikt zijn.
                    </p>
                  </div>
                  <div className="flex flex-col items-start gap-y-3">
                    <p className="text-sm font-bold">Wie draagt de kosten?</p>
                    <p className="text-sm">
                      De koper draagt de kosten van de retourzending.
                    </p>
                  </div>
                  <div className="flex flex-col items-start gap-y-3">
                    <p className="text-sm font-bold">
                      Wat gebeurt er als ik een artikel ontvang dat duidelijk
                      defect is?
                    </p>
                    <p className="text-sm">
                      Als u een defect artikel ontvangt (beschadigd, gebroken,
                      etc.) sturen wij u gratis een vervangend product.
                    </p>
                  </div>
                  <div className="flex flex-col items-start gap-y-3">
                    <p className="text-sm font-bold">
                      Terugbetalingen op afgeprijsde goederen (uitverkoop)
                    </p>
                    <p className="text-sm">
                      Wij bieden een terugbetaling via een waardebon voor
                      producten die tegen gereduceerde prijzen in de uitverkoop
                      zijn gekocht.
                    </p>
                  </div>
                  <div className="flex flex-col items-start gap-y-3">
                    <p className="text-sm font-bold">
                      Wanneer kan de klant een terugbetaling verwachten?
                    </p>
                    <p className="text-sm">
                      Een terugbetaling vindt plaats binnen 14 dagen na
                      ontvangst van de retourzending in de vorm van een
                      terugbetaling van de oorspronkelijk gekozen betaalmethode.
                    </p>
                  </div>
                  <div className="flex flex-col items-start gap-y-3">
                    <p className="text-sm font-bold">Gevolgen van herroeping</p>
                    <p className="text-sm">
                      Als u dit contract herroept, zullen wij alle betalingen
                      die we van u hebben ontvangen, inclusief leveringskosten
                      (met uitzondering van eventuele extra kosten die
                      voortvloeien uit uw keuze voor een andere leveringsmethode
                      dan de goedkoopste standaardlevering die wij aanbieden)
                      onmiddellijk en uiterlijk binnen veertien dagen na de dag
                      waarop wij op de hoogte zijn gesteld van uw herroeping van
                      dit contract, aan u terugbetalen. We kunnen de
                      terugbetaling weigeren totdat we de goederen hebben
                      terugontvangen. De termijn is gehaald als u de goederen
                      terugstuurt voordat de termijn van 60 dagen is verstreken.
                      U draagt de directe kosten van het terugzenden van de
                      goederen. U bent alleen aansprakelijk voor een
                      waardevermindering van de goederen als deze
                      waardevermindering het gevolg is van een andere omgang met
                      de goederen dan nodig is om de aard, kenmerken en werking
                      van de goederen vast te stellen.
                    </p>
                    <p className="text-sm">
                      We accepteren alleen retourzendingen in perfecte en
                      ongebruikte staat.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
