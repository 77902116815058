import React from "react";
// ROUTES
import { Route, Routes, BrowserRouter, Navigate, Link } from "react-router-dom";
// HOOKS
import { useAuth } from "./hooks/useAuth";

import LoadingComponent from "./Pages/components/LoadingComponent";
// STYLES
import "./App.css";

// COMPONENTS
import Header from "./components/Header";

// PAGES
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import { NoData } from "./components/NoData";
import Home from "./Pages/Home";
import TermsAndConditions from "./Pages/TermsAndConditions";
import ProductPage from "./Pages/ProductPage";
import Footer from "./Pages/components/Footer";
import Checkout from "./Pages/Checkout";
import Tracking from "./Pages/Tracking";
import RefundPolicy from "./Pages/RefundPolicy";
import ShippingPolicy from "./Pages/ShippingPolicy";


function App() {
  const { user, isReady, settings } = useAuth();

  // if (!navigator.onLine) {
  //   return <NoData />;
  // }

  if (!isReady) {
    return <LoadingComponent />;
  }

  // if (isReady && !settings) {
  //   return <NoData />;
  // }




  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tracking" element={<Tracking />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/shipping-policy" element={<ShippingPolicy />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/product/camera" element={<ProductPage />} />
          <Route path="/checkout/:quantity/:color" element={<Checkout />} />
          <Route
            path="*"
            element={
              <section className="bg-white darks:bg-gray-900">
                <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                  <div className="mx-auto max-w-screen-sm text-center">
                    <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-#280134-600 darks:text-[#280134]">
                      404
                    </h1>
                    <p className="mb-4 text-3xl tracking-tight font-bold text-red-900 md:text-4xl darks:text-white">
                      Something's missing.
                    </p>
                    <p className="mb-4 text-lg font-light text-gray-500 darks:text-gray-400">
                      Sorry, we can't find that page. You'll find lots to
                      explore on the home page.
                    </p>
                    <Link
                      to="/"
                      className="inline-flex text-white bg-black focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center darks:focus:ring-primary-900 my-4"
                    >
                      Back to Homepage
                    </Link>
                  </div>
                </div>
              </section>
            }
          />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
