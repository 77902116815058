import React from "react";

const TermsAndConditions = () => {
  return (
    <section>
      <div className="flex flex-col items-center justify-center px-5 md:px-10">
        {/* Title Container */}
        <div className="flex h-auto min-w-[100vw] flex-col items-center justify-end py-6 md:h-64">
          <div className="flex flex-col items-center gap-y-4 py-5">
            <h1 className="text-3xl font-bold md:text-5xl">
              Algemene Voorwaarden
            </h1>
            <p className="text-sm text-[#808080] sm:text-base">
              Deze Algemene Voorwaarden zijn van toepassing op alle contracten
              die worden afgesloten tussen een consument of ondernemer (hierna
              "Klant") en amsterzoom.nl (hierna "Verkoper") met betrekking tot
              alle goederen en/of diensten die in de online shop van de Verkoper
              worden getoond. De opname van de eigen voorwaarden van de Klant
              wordt hierbij afgewezen, tenzij anders overeengekomen.
            </p>
          </div>
        </div>
        {/* Content Container */}
        <div className="mx-auto w-full max-w-5xl py-12 md:py-16 lg:py-20">
          {/* Component */}
          <div className="flex flex-col items-center gap-y-14">
            <h2 className="text-2xl font-bold">1 Toepassingsgebied</h2>
            <p className="text-sm">
              1.1 Deze Algemene Voorwaarden (hierna "Voorwaarden") van
              amsterzoom.nl (hierna "Verkoper") zijn van toepassing op alle
              contracten die worden afgesloten tussen een consument of
              ondernemer (hierna "Klant") en de Verkoper met betrekking tot alle
              goederen en/of diensten die in de online shop van de Verkoper
              worden getoond. De opname van de eigen voorwaarden van de Klant
              wordt hierbij afgewezen, tenzij anders overeengekomen.
            </p>
            <p className="text-sm">
              1.2 Een consument in de zin van deze Voorwaarden is elke
              natuurlijke persoon die een rechtshandeling aangaat voor
              doeleinden die overwegend buiten hun handels-, bedrijfs- of
              beroepsactiviteit vallen. Een ondernemer in de zin van deze
              Voorwaarden is een natuurlijke of rechtspersoon of een
              vennootschap met rechtspersoonlijkheid die, bij het aangaan van
              een rechtshandeling, handelt in de uitoefening van hun handels-,
              bedrijfs- of beroepsactiviteit.
            </p>
            <p className="text-sm">
              1.3 Het onderwerp van het contract kan, afhankelijk van de
              productbeschrijving van de Verkoper, zowel de aankoop van goederen
              door middel van een enkele levering als de aankoop van goederen
              door middel van permanente levering (hierna
              "Abonnementsovereenkomst") omvatten. In het geval van een
              Abonnementsovereenkomst verbindt de Verkoper zich ertoe om de
              contractueel verschuldigde goederen aan de Klant te leveren voor
              de duur van de overeengekomen contractperiode met de contractueel
              verschuldigde intervallen.
            </p>
            <p className="text-sm">
              Onjuiste adres verstrekt door de koper: Als een onjuist adres
              wordt verstrekt door de verkoper bij het plaatsen van een
              bestelling, zijn wij niet verplicht het factuurbedrag terug te
              betalen totdat de zending zelfstandig naar ons is teruggestuurd.
              Wij bieden echter omleiding naar het juiste adres aan; neem
              hiervoor contact met ons op via e-mail.
            </p>

            {/* Additional sections can be added similarly */}

            <h2 className="text-2xl font-bold">
              2) Totstandkoming van het contract
            </h2>
            <p className="text-sm">
              2.1 De productbeschrijvingen in de online shop van de Verkoper
              vormen geen bindende aanbiedingen van de kant van de Verkoper,
              maar dienen om een bindend aanbod van de Klant te doen.
            </p>
            <p className="text-sm">
              2.2 De Klant kan het aanbod indienen via het geïntegreerde online
              bestelformulier in de online shop van de Verkoper. Hiervoor moet
              de Klant eerst de geselecteerde goederen in het virtuele
              winkelwagentje plaatsen en vervolgens het elektronische
              bestelproces doorlopen door op de knop te klikken die het
              bestelproces voltooit. Op deze manier dient de Klant een juridisch
              bindend contractaanbod in met betrekking tot de goederen in het
              winkelwagentje.
            </p>

            <h2 className="text-2xl font-bold">
              11) Alternatieve geschillenbeslechting
            </h2>
            <p className="text-sm">
              11.1 De Europese Commissie biedt een platform voor online
              geschillenbeslechting (ODR), dat te vinden is via de volgende
              link:{" "}
              <a
                href="https://ec.europa.eu/consumers/odr"
                className="text-blue-500 underline"
              >
                https://ec.europa.eu/consumers/odr
              </a>
              . Dit platform dient als toegangspunt voor de buitengerechtelijke
              beslechting van geschillen die voortvloeien uit online koop- of
              dienstverleningscontracten waarbij een consument betrokken is.
            </p>
            <p className="text-sm">
              11.2 De Verkoper is niet verplicht of bereid deel te nemen aan een
              geschillenbeslechtingsprocedure voor een
              consumentenarbitragecommissie.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsAndConditions;
