import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { FaShippingFast } from "react-icons/fa";
import { RiExchangeFundsFill } from "react-icons/ri";
import { IoCameraOutline } from "react-icons/io5";
import { MdBatteryChargingFull } from "react-icons/md";
import { LuPartyPopper } from "react-icons/lu";
import { GoClock } from "react-icons/go";
import { GrTechnology } from "react-icons/gr";
import { MdVerified } from "react-icons/md";

import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";

import Vid1 from "../assets/testimonialvideo.mp4";
import Vid2 from "../assets/video2.mp4";
import ImageSlider from "./components/ImageSlider";

const productImages = {
  large1:
    "https://nostalgixdigital.com/cdn/shop/files/DesignohneTitel_7.png?v=1720888940&width=800",
  large2:
    "https://nostalgixdigital.com/cdn/shop/files/DesignohneTitel_5.png?v=1720380654&width=800",
  large3:
    "https://nostalgixdigital.com/cdn/shop/files/Bildschirm_foto_2024-06-24_um_19.42.20_cf00a61e-b1ca-45ea-975f-3118b0099de7.png?v=1719518726&width=600",
  large4:
    "https://nostalgixdigital.com/cdn/shop/files/DesignohneTitel_8.png?v=1720888939&width=800",
};

export default function ProductPage() {
    const { config } = useAuth()
  const [currentImage, setCurrentImage] = React.useState(productImages.large1);
    const [selectedColor, setSelectedColor] = React.useState("black")
  const [quantity, setQuantity] = React.useState(1)
  const navigate = useNavigate()

  return (
    <div className="w-full bg-[#fcfdff]">
      <section class="md:py-12 mt-[-70px] md:mt-[0px] sm:py-16">
        <div class="container mx-auto px-4">
          <nav class="flex">
            <ol role="list" class="flex items-center">
              <li class="text-left">
                <div class="-m-1">
                  <a
                    href="#"
                    class="rounded-md p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800"
                  >
                    {" "}
                  </a>
                </div>
              </li>

              <li class="text-left">
                <div class="flex items-center">
                  <span class="mx-2 text-gray-400"></span>
                  <div class="-m-1">
                    <a
                      href="#"
                      class="rounded-md p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800"
                    >
                      {" "}
                    </a>
                  </div>
                </div>
              </li>

              <li class="text-left">
                <div class="flex items-center">
                  <span class="mx-2 text-gray-400"></span>
                  <div class="-m-1">
                    <a
                      href="#"
                      class="rounded-md p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800"
                      aria-current="page"
                    >
                      {" "}
                    </a>
                  </div>
                </div>
              </li>
            </ol>
          </nav>

          <div class="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16">
            <div class="lg:col-span-3 lg:row-end-1">
              <div class="lg:flex lg:items-start">
                <div class="lg:order-2 lg:ml-5">
                  <div class="max-w-xl overflow-hidden rounded-lg">
                    <img
                      class="h-full w-full max-w-full object-cover"
                      src={currentImage}
                      alt=""
                    />
                  </div>
                </div>

                <div class="mt-2 w-full lg:order-1 lg:w-32 lg:flex-shrink-0">
                  <div class="flex flex-row gap-3 items-center justify-center md:items-start lg:flex-col">
                    <button
                      type="button"
                      class={`flex-0 aspect-square mb-3 h-20 overflow-hidden rounded-lg ${
                        currentImage === productImages.large1 && "border-2"
                      } border-gray-900 text-center`}
                    >
                      <img
                        class="h-full w-full object-cover"
                        onClick={() => setCurrentImage(productImages.large1)}
                        src="https://nostalgixdigital.com/cdn/shop/files/DesignohneTitel_7.png?v=1720888940&width=128"
                        alt=""
                      />
                    </button>
                    <button
                      type="button"
                      class={`flex-0 aspect-square mb-3 h-20 overflow-hidden ${
                        currentImage === productImages.large2 && "border-2"
                      } rounded-lg border-gray-900 text-center`}
                    >
                      <img
                        class="h-full w-full object-cover"
                        onClick={() => setCurrentImage(productImages.large2)}
                        src="https://nostalgixdigital.com/cdn/shop/files/DesignohneTitel_5.png?v=1720380654&width=128"
                        alt=""
                      />
                    </button>
                    <button
                      type="button"
                      onClick={() => setCurrentImage(productImages.large3)}
                      class={`flex-0 aspect-square border-gray-900 mb-3 h-20 overflow-hidden rounded-lg ${
                        currentImage === productImages.large3 && "border-2"
                      } text-center`}
                    >
                      <img
                        class="h-full w-full object-cover"
                        src="https://nostalgixdigital.com/cdn/shop/files/Bildschirm_foto_2024-06-24_um_19.42.20_cf00a61e-b1ca-45ea-975f-3118b0099de7.png?v=1719518726&width=128"
                        alt=""
                      />
                    </button>
                    <button
                      type="button"
                      onClick={() => setCurrentImage(productImages.large4)}
                      class={`flex-0 aspect-square border-gray-900 mb-3 h-20 overflow-hidden rounded-lg ${
                        currentImage === productImages.large4 && "border-2"
                      }  text-center`}
                    >
                      <img
                        class="h-full w-full object-cover"
                        src="https://nostalgixdigital.com/cdn/shop/files/DesignohneTitel_8.png?v=1720888939&width=800"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="lg:col-span-2 lg:row-span-2 lg:row-end-2">
              <h1 class="text-center md:text-left text-2xl font-bold text-gray-900 sm:text-3xl">
                AmsterZoom™ Pro digitale camera
              </h1>

              <div class="mt-10 flex flex-col items-center justify-between space-y-4 border-t border-b py-4 sm:flex-row sm:space-y-0">
                <div class="flex items-end">
                  <h1 class="font-bold"></h1>
                  <span class="text-green-500 font-bold text-[23px]">
                    €{config.product_fee_eur}
                  </span>
                  <strike class="pl-[12px] font-bold text-[23px]">
                    €{config.previous_price}
                  </strike>
                  <span class="bg-green-600 text-white font-medium me-2 ml-[20px] mb-[6px] px-2.5 rounded dark:bg-green-900 dark:text-green-300">
                    redden €{config.previous_price - config.product_fee_eur}
                  </span>
                </div>
              </div>

              <div
                className="flex gap-3 justify-center md:justify-start mt-[15px] mb-[20px]"
                style={{ alignItems: "center" }}
              >
                <div class="flex">
                  <div class="relative inline-flex">
                    <div class="w-5 h-5 bg-green-500 rounded-full"></div>
                    <div class="w-5 h-5 bg-green-500 rounded-full absolute top-0 left-0 animate-ping"></div>
                    <div class="w-5 h-5 bg-green-500 rounded-full absolute top-0 left-0 animate-pulse"></div>
                  </div>
                </div>
                <p className="text-green-500 text-[19px]">
                  Beperkte voorraden beschikbaar! Haast je!
                </p>
              </div>

              <h2 class="mt-8 text-base text-gray-900">Kleur</h2>
              <div class="mt-3 flex select-none flex-wrap items-center gap-1">
                <label class="">
                  <input
                    type="radio"
                    name="type"
                    value="Powder"
                    class="peer sr-only"
                    checked={selectedColor === "black"}
                    onChange={(e) => setSelectedColor("black")}
                  />
                  <p class="peer-checked:bg-black peer-checked:text-white rounded-lg border border-black px-6 py-2 font-bold">
                    Black
                  </p>
                </label>
                <label class="">
                  <input
                    type="radio"
                    name="type"
                    value="Whole Bean"
                    class="peer sr-only"
                    checked={selectedColor === "pink"}
                    onChange={(e) => setSelectedColor("pink")}
                  />
                  <p class="peer-checked:bg-pink-600 peer-checked:text-white rounded-lg border border-black px-6 py-2 font-bold">
                    Pink
                  </p>
                </label>
              </div>

              <div className="pt-[20px]" />
              <label for="counter-input" class="">
                Kies hoeveelheid:
              </label>
              <div class="flex items-center justify-between">
                <div class="flex items-center">
                  <button
                    type="button"
                    id="decrement-button"
                    data-input-counter-decrement="counter-input"
                    onClick={() => {
                      if (quantity !== 1) {
                        setQuantity((prev) => prev - 1);
                      }
                    }}
                    class="inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                  >
                    <svg
                      class="h-2.5 w-2.5 text-gray-900 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 18 2"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M1 1h16"
                      />
                    </svg>
                  </button>
                  <input
                    type="text"
                    id="counter-input"
                    data-input-counter
                    class="w-10 shrink-0 border-0 bg-transparent text-center text-sm font-medium text-gray-900 focus:outline-none focus:ring-0 dark:text-white"
                    placeholder=""
                    value={quantity}
                    readOnly
                    required
                  />
                  <button
                    type="button"
                    id="increment-button"
                    data-input-counter-increment="counter-input"
                    onClick={() => {
                      setQuantity((prev) => prev + 1);
                    }}
                    class="inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                  >
                    <svg
                      class="h-2.5 w-2.5 text-gray-900 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 18 18"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 1v16M1 9h16"
                      />
                    </svg>
                  </button>
                </div>
                <div class="text-end md:order-4 md:w-32">
                  <p class="text-base font-bold text-gray-900 dark:text-white">
                    €{config.product_fee_eur * quantity}
                  </p>
                </div>
              </div>

              <button
                type="button"
                onClick={() => {
                  window.fbq('track', 'AddToCart', {
                    value: 87.2,
                    currency: 'USD',
                  })
                  navigate(`/checkout/${quantity}/${selectedColor}`)
                }}
                class="inline-flex mt-[20px] w-full items-center justify-center rounded-md border-2 border-transparent bg-black bg-none px-12 py-3 text-center text-base font-bold text-white transition-all duration-200 ease-in-out focus:shadow"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="shrink-0 mr-3 h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                  />
                </svg>
                koop nu
              </button>

              <ul
                class="mt-8 space-y-2"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 20,
                }}
              >
                <li
                  class="flex bg-gray-200 items-center rounded-md gap-[10px] text-left text-sm font-medium text-gray-600 p-[10px]"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  <RiExchangeFundsFill size={20} color="black" />
                  <div className="flex flex-col">
                    <p className="font-bold text-[13px]">30 dagen</p>
                    <p className="text-[13px]"> Geldteruggarantie</p>
                  </div>
                </li>
                <li
                  class="flex bg-gray-200 items-center rounded-md gap-[10px] text-left text-sm font-medium text-gray-600 p-[10px]"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  <FaShippingFast size={20} color="black" />
                  <div className="flex flex-col">
                    <p className="font-bold text-[13px]">krijgen</p>
                    <p className="text-[13px]">geen verzendkosten</p>
                  </div>
                </li>
              </ul>

              <div
                className="flex"
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 30,
                  marginTop: 40,
                }}
              >
                <video width="170" className="rounded-lg" height="170" controls>
                  <source src={Vid1} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                <video width="170" className="rounded-lg" height="170" controls>
                  <source src={Vid2} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>

              <div className="mt-[20px] p-[20px]">
                <h1 className="font-bold text-[22px] pb-[10px]">
                  Maak onvergetelijke foto's!
                </h1>
                <p>
                  Leg nostalgische herinneringen vast in authentieke stijl uit
                  het begin van de jaren 2000 met de ideale mini digitale
                  camera. Geniet van de charme van korrelig afbeeldingen ✨
                </p>
              </div>

              <ul class="divide-y divide-gray-200 p-[20px] mt-[20px]">
                <li class="text-left">
                  <label for="accordion-1" class="flex flex-col">
                    <input
                      class="peer hidden"
                      type="checkbox"
                      id="accordion-1"
                    />
                    <div class="before:absolute before:-left-6 before:block before:text-xl before:text-green-400 before:content-['–'] peer-checked:before:content-['+'] relative ml-4 cursor-pointer select-none items-center py-4 pr-2">
                      <h3 class="text-[18px] font-bold">Verzending & Retour</h3>
                    </div>
                    <div class=":hidden pr-2">
                      <div class="pb-5">
                        <p class="text-[16px]">
                          <b>Verzenden:</b>Omdat uw tevredenheid belangrijk is
                          naar ons toe, dan vergoeden wij de verzendkosten voor
                          u. De gemiddelde levertijd bedraagt ​​7-12 werkdagen.
                        </p>
                        <br />
                        <p>
                          <b>Opbrengst:</b> Niet tevreden met uw digitale
                          camera? Geen zorgen. Je kunt ze binnen 30 dagen
                          retourneren. Het het maakt niet uit of het product is
                          geopend en gebruikt of niet (hoe had je het anders
                          geprobeerd?). Zodra wij ontvangst van uw retour
                          ontvangt u een volledige terugbetaling.
                        </p>
                      </div>
                    </div>
                  </label>
                </li>

                <li class="text-left">
                  <label for="accordion-13" class="flex flex-col">
                    <input
                      class="peer hidden"
                      type="checkbox"
                      id="accordion-13"
                    />
                    <div class="before:absolute before:-left-6 before:block before:text-xl before:text-green-400 before:content-['–'] peer-checked:before:content-['+'] relative ml-4 cursor-pointer select-none items-center py-4 pr-2">
                      <h3 class="text-[18px] font-bold">Gebruiksaanwijzing</h3>
                    </div>
                    <div class="peer-checked:hidden pr-2">
                      <div class="pb-5">
                        <p class="text-[16px]">
                          Plaats de oplaadbare Li-Ion-batterij (meegeleverd).
                          het cameracompartiment. Sluit de batterij compartiment
                          veilig. Schakel de camera in door op te drukken de
                          aan/uit-knop. Kadreer uw afbeelding met behulp van de
                          zoeker of het LCD-scherm. Ontdek verschillende films
                          modusopties via het bedieningspaneel. Pas instellingen
                          aan zoals zoom en focus met behulp van de
                          bedieningsknoppen. druk op de ontspanknop half in om
                          scherp te stellen en druk er vervolgens op volledig om
                          een ​​foto te maken.
                        </p>
                      </div>
                    </div>
                  </label>
                </li>

                <li class="text-left">
                  <label for="accordion-14" class="flex flex-col">
                    <input
                      class="peer hidden"
                      type="checkbox"
                      id="accordion-14"
                    />
                    <div class="before:absolute before:-left-6 before:block before:text-xl before:text-green-400 before:content-['–'] peer-checked:before:content-['+'] relative ml-4 cursor-pointer select-none items-center py-4 pr-2">
                      <h3 class="text-[18px font-bold">Betaalmethoden</h3>
                    </div>
                    <div class="peer-checked:hidden pr-2">
                      <div class="pb-5">
                        <img
                          width={175}
                          height={70}
                          style={{ margin: "auto" }}
                          src="https://getsby.com/wp-content/uploads/2023/01/Visa-Mastercard-1-1024x378.png"
                        />
                      </div>
                    </div>
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        {/* Container */}
        <div className="mx-auto max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
          {/* Heading Div */}
          <div className="mx-auto mb-[30px] w-full max-w-3xl text-center">
            <h2 className="text-3xl font-semibold md:text-5xl">
              Iphone vs. Amsterzoom
            </h2>
          </div>

          <ReactCompareSlider
            itemOne={
              <ReactCompareSliderImage
                src="https://nostalgixdigital.com/cdn/shop/files/Bildschirm_foto_2024-07-05_um_21.41.55.png?v=1720208560&width=800"
                alt="Image one"
              />
            }
            itemTwo={
              <ReactCompareSliderImage
                src="https://nostalgixdigital.com/cdn/shop/files/Bildschirm_foto_2024-07-05_um_21.42.19.png?v=1720208563&width=800"
                alt="Image two"
              />
            }
            style={{ width: "60%", margin: "auto", borderRadius: 30 }}
          />
        </div>
      </section>
      <div
        class="mx-auto"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 30,
          padding: 80,
          flexWrap: "wrap",
        }}
        className="flex-row md:flex-row"
      >
        <div class="lg:w-[40%] text-center">
          <GrTechnology class="mx-auto h-auto w-7 md:w-9 text-gray-800 dark:text-neutral-200" />
          <h3 class="text-lg font-semibold text-gray-800 dark:text-neutral-200">
            Combineert retrostijl met moderne technologie
          </h3>
        </div>

        <div class="lg:w[40%] text-center">
          <IoCameraOutline class="mx-auto h-auto w-7 md:w-9 text-gray-800 dark:text-neutral-200" />

          <div class="mt-2 sm:mt-6">
            <h3 class="text-lg font-semibold text-gray-800 dark:text-neutral-200">
              Instant retro-charme
            </h3>
          </div>
        </div>

        <div class="lg:w[40%]  text-center">
          <MdBatteryChargingFull class="mx-auto h-auto w-7 md:w-9 text-gray-800 dark:text-neutral-200" />

          <div class="mt-2 sm:mt-6">
            <h3 class="text-lg font-semibold text-gray-800 dark:text-neutral-200">
              Lange levensduur van de batterij
            </h3>
          </div>
        </div>

        <div class="lg:w-[40%] text-center">
          <LuPartyPopper class="mx-auto h-auto w-7 md:w-9 text-gray-800 dark:text-neutral-200" />
          <h3 class="text-lg font-semibold text-gray-800 dark:text-neutral-200">
            Perfect voor feestjes, reizen en alledaagse momenten.
          </h3>
        </div>

        <div class="col-span-6 max-w[40%] col-start-4 sm:col-span-4 text-center">
          <GoClock class="mx-auto h-auto w-7 md:w-9 text-gray-800 dark:text-neutral-200" />

          <div class="mt-2 sm:mt-6">
            <h3 class="text-lg font-semibold text-gray-800 dark:text-neutral-200">
              Tijdloze herinneringen
            </h3>
          </div>
        </div>
      </div>

      <section>
        {/* Container */}
        <div className="mx-auto max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
          {/* Heading Div */}
          <div className="mx-auto mb-[30px] w-full max-w-3xl text-center">
            <h2 className="text-3xl font-bold pb-[50px] md:text-3xl">
              Maak uw zomer uniek
            </h2>
            <p className="text-3xl font-bold md:text-3xl">
              #shotwithamsterzoom
            </p>
          </div>

          <ImageSlider
            images={[
              "https://cdn.shopify.com/s/files/1/0886/0604/8531/files/a03e1c8d08e0ffb5ffbc7bf843389097.jpg?v=1719250097&width=2048",
              "https://cdn.shopify.com/s/files/1/0886/0604/8531/files/9449b179aa79d037d6e9d96df22ef63a.jpg?v=1719250067&width=2048",
              "https://cdn.shopify.com/s/files/1/0886/0604/8531/files/5aa78d7079a74344ac79d579e3ee2db7.jpg?v=1719250067&width=2048",
              "https://cdn.shopify.com/s/files/1/0886/0604/8531/files/01ac92b041cc8af61118fc213f4eeb49.jpg?v=1719250067&width=2048",
              "https://cdn.shopify.com/s/files/1/0886/0604/8531/files/c5429652b12318bd228224374f4a6421.jpg?v=1719250067&width=2048",
              "https://cdn.shopify.com/s/files/1/0886/0604/8531/files/8a6fad84f24d4b890e8d9ba516923e5b_1.jpg?v=1719250097&width=2048",
              "https://cdn.shopify.com/s/files/1/0886/0604/8531/files/d34d5c769380250d0f4b828723891942_9f7090f3-c288-456b-ad29-b5cfbc593c68.jpg?v=1719250109&width=2048",
              "https://cdn.shopify.com/s/files/1/0886/0604/8531/files/6dd3782af19e5e27f42ff9feb10c5c2a_9f7f61c3-3e48-4e06-9355-e7b426e83d0b.jpg?v=1719176925&width=2048",
              "https://cdn.shopify.com/s/files/1/0886/0604/8531/files/4a38daf3144f2e0fc44367d1b8221271.jpg?v=1719250067&width=2048",
              "https://cdn.shopify.com/s/files/1/0886/0604/8531/files/d1e634377a9f0ec492cbbd637a6030d3.jpg?v=1719250108&width=2048",
              "https://cdn.shopify.com/s/files/1/0886/0604/8531/files/b00e7cb8975d2974528fcc6366fc8a34.jpg?v=1719250097&width=2048",
              "https://cdn.shopify.com/s/files/1/0886/0604/8531/files/8a6fad84f24d4b890e8d9ba516923e5b_1.jpg?v=1719250097&width=2048",
              "https://cdn.shopify.com/s/files/1/0886/0604/8531/files/6a1f8bf765dd0a1e5ecc2adfcb3ad9a3.jpg?v=1719250097&width=2048",
              "https://cdn.shopify.com/s/files/1/0886/0604/8531/files/20bf4fbb0d5bb1414230bb821c9f69f6.jpg?v=1719905643&width=2048",
              "https://cdn.shopify.com/s/files/1/0886/0604/8531/files/1332bce9b75d2d1235fe216cbbf36931.jpg?v=1719905643&width=2048",
              "https://cdn.shopify.com/s/files/1/0886/0604/8531/files/2e10880eb99887f9d471cad0841341b4.jpg?v=1719905643&width=2048",
              "https://cdn.shopify.com/s/files/1/0886/0604/8531/files/cd4473abdf153d8fc5fc81ffcff60972.jpg?v=1719905643&width=2048",
            ]}
            duration={60}
          />
        </div>
      </section>

      <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div class="md:grid md:grid-cols-2 md:gap-10 lg:gap-16 md:items-center">
          <div class="hidden md:block mb-24 md:mb-0 sm:px-6">
            <div class="relative">
              <img
                class="rounded-xl"
                src="https://images.loox.io/uploads/2024/7/13/YqL5AyskI.jpg"
                alt="Image Description"
              />

              <div class="absolute bottom-0 start-0 -z-[1] translate-y-10 -translate-x-14">
                <svg
                  class="max-w-40 h-auto text-gray-400 dark:text-neutral-700"
                  width="696"
                  height="653"
                  viewBox="0 0 696 653"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="72.5" cy="29.5" r="29.5" fill="currentColor" />
                  <circle cx="171.5" cy="29.5" r="29.5" fill="currentColor" />
                  <circle cx="270.5" cy="29.5" r="29.5" fill="currentColor" />
                  <circle cx="369.5" cy="29.5" r="29.5" fill="currentColor" />
                  <circle cx="468.5" cy="29.5" r="29.5" fill="currentColor" />
                  <circle cx="567.5" cy="29.5" r="29.5" fill="currentColor" />
                  <circle cx="666.5" cy="29.5" r="29.5" fill="currentColor" />
                  <circle cx="29.5" cy="128.5" r="29.5" fill="currentColor" />
                  <circle cx="128.5" cy="128.5" r="29.5" fill="currentColor" />
                  <circle cx="227.5" cy="128.5" r="29.5" fill="currentColor" />
                  <circle cx="326.5" cy="128.5" r="29.5" fill="currentColor" />
                  <circle cx="425.5" cy="128.5" r="29.5" fill="currentColor" />
                  <circle cx="524.5" cy="128.5" r="29.5" fill="currentColor" />
                  <circle cx="623.5" cy="128.5" r="29.5" fill="currentColor" />
                  <circle cx="72.5" cy="227.5" r="29.5" fill="currentColor" />
                  <circle cx="171.5" cy="227.5" r="29.5" fill="currentColor" />
                  <circle cx="270.5" cy="227.5" r="29.5" fill="currentColor" />
                  <circle cx="369.5" cy="227.5" r="29.5" fill="currentColor" />
                  <circle cx="468.5" cy="227.5" r="29.5" fill="currentColor" />
                  <circle cx="567.5" cy="227.5" r="29.5" fill="currentColor" />
                  <circle cx="666.5" cy="227.5" r="29.5" fill="currentColor" />
                  <circle cx="29.5" cy="326.5" r="29.5" fill="currentColor" />
                  <circle cx="128.5" cy="326.5" r="29.5" fill="currentColor" />
                  <circle cx="227.5" cy="326.5" r="29.5" fill="currentColor" />
                  <circle cx="326.5" cy="326.5" r="29.5" fill="currentColor" />
                  <circle cx="425.5" cy="326.5" r="29.5" fill="currentColor" />
                  <circle cx="524.5" cy="326.5" r="29.5" fill="currentColor" />
                  <circle cx="623.5" cy="326.5" r="29.5" fill="currentColor" />
                  <circle cx="72.5" cy="425.5" r="29.5" fill="currentColor" />
                  <circle cx="171.5" cy="425.5" r="29.5" fill="currentColor" />
                  <circle cx="270.5" cy="425.5" r="29.5" fill="currentColor" />
                  <circle cx="369.5" cy="425.5" r="29.5" fill="currentColor" />
                  <circle cx="468.5" cy="425.5" r="29.5" fill="currentColor" />
                  <circle cx="567.5" cy="425.5" r="29.5" fill="currentColor" />
                  <circle cx="666.5" cy="425.5" r="29.5" fill="currentColor" />
                  <circle cx="29.5" cy="524.5" r="29.5" fill="currentColor" />
                  <circle cx="128.5" cy="524.5" r="29.5" fill="currentColor" />
                  <circle cx="227.5" cy="524.5" r="29.5" fill="currentColor" />
                  <circle cx="326.5" cy="524.5" r="29.5" fill="currentColor" />
                  <circle cx="425.5" cy="524.5" r="29.5" fill="currentColor" />
                  <circle cx="524.5" cy="524.5" r="29.5" fill="currentColor" />
                  <circle cx="623.5" cy="524.5" r="29.5" fill="currentColor" />
                  <circle cx="72.5" cy="623.5" r="29.5" fill="currentColor" />
                  <circle cx="171.5" cy="623.5" r="29.5" fill="currentColor" />
                  <circle cx="270.5" cy="623.5" r="29.5" fill="currentColor" />
                  <circle cx="369.5" cy="623.5" r="29.5" fill="currentColor" />
                  <circle cx="468.5" cy="623.5" r="29.5" fill="currentColor" />
                  <circle cx="567.5" cy="623.5" r="29.5" fill="currentColor" />
                  <circle cx="666.5" cy="623.5" r="29.5" fill="currentColor" />
                </svg>
              </div>
            </div>
          </div>

          <div>
            <blockquote class="relative">
              <svg
                class="absolute top-0 start-0 transform -translate-x-8 -translate-y-4 size-24 text-gray-200 dark:text-neutral-700"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path
                  d="M7.39762 10.3C7.39762 11.0733 7.14888 11.7 6.6514 12.18C6.15392 12.6333 5.52552 12.86 4.76621 12.86C3.84979 12.86 3.09047 12.5533 2.48825 11.94C1.91222 11.3266 1.62421 10.4467 1.62421 9.29999C1.62421 8.07332 1.96459 6.87332 2.64535 5.69999C3.35231 4.49999 4.33418 3.55332 5.59098 2.85999L6.4943 4.25999C5.81354 4.73999 5.26369 5.27332 4.84476 5.85999C4.45201 6.44666 4.19017 7.12666 4.05926 7.89999C4.29491 7.79332 4.56983 7.73999 4.88403 7.73999C5.61716 7.73999 6.21938 7.97999 6.69067 8.45999C7.16197 8.93999 7.39762 9.55333 7.39762 10.3ZM14.6242 10.3C14.6242 11.0733 14.3755 11.7 13.878 12.18C13.3805 12.6333 12.7521 12.86 11.9928 12.86C11.0764 12.86 10.3171 12.5533 9.71484 11.94C9.13881 11.3266 8.85079 10.4467 8.85079 9.29999C8.85079 8.07332 9.19117 6.87332 9.87194 5.69999C10.5789 4.49999 11.5608 3.55332 12.8176 2.85999L13.7209 4.25999C13.0401 4.73999 12.4903 5.27332 12.0713 5.85999C11.6786 6.44666 11.4168 7.12666 11.2858 7.89999C11.5215 7.79332 11.7964 7.73999 12.1106 7.73999C12.8437 7.73999 13.446 7.97999 13.9173 8.45999C14.3886 8.93999 14.6242 9.55333 14.6242 10.3Z"
                  fill="currentColor"
                />
              </svg>

              <div class="relative z-10">
                <p class="text-xs font-semibold text-gray-500 tracking-wide uppercase mb-3 dark:text-neutral-200">
                  Uitgelichte recensie
                </p>

                <p class="text-xl font-medium italic text-gray-800 md:text-2xl md:leading-normal xl:text-3xl xl:leading-normal dark:text-neutral-200">
                  De camera is compact, lichtgewicht en gemakkelijk te
                  gebruiken. Het biedt levendige beelden met hoge resolutie met
                  16x digitale zoom, a 2,7 inch LCD, verschillende modi, goede
                  batterijduur en micro SD steun. Betaalbaar en aantrekkelijk,
                  het is een geweldig cadeau.
                </p>
              </div>

              <footer class="mt-6">
                <div class="flex flex-col items-center">
                  <div class="md:hidden flex-shrink-0">
                    <img
                      class=" rounded-full"
                      src="https://images.loox.io/uploads/2024/7/13/YqL5AyskI.jpg"
                      alt="Image Description"
                    />
                  </div>
                  <div class="ms-4 md:ms-0 mt-[20px]">
                    <div class="text-base flex items-center font-semibold text-gray-800 dark:text-neutral-200">
                      Sarah F. <MdVerified />
                    </div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>

      {/* FAQS */}
      <section class="bg-white w-[80%] mx-auto dark:bg-gray-900">
        <div class="py-8 lg:py-16 mx-auto max-w-screen-xl px-4">
          <h2 class="mb-8 lg:mb-16 text-3xl font-extrabold tracking-tight leading-tight text-center text-gray-900 dark:text-white md:text-4xl">
            FAQs (Veel Gestelde Vragen)
          </h2>
          <div className="space-y-4">
            <details className="group [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
                <h2 className="font-medium">Wat zit erbij?</h2>
                <svg
                  className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </summary>

              <p className="mt-4 px-4 font-bold leading-relaxed text-gray-700">
                1. Digitale camera
              </p>

              <p className="mt-4 px-4 font-bold leading-relaxed text-gray-700">
                2. Oplaadbare Li-ionbatterij
              </p>

              <p className="mt-4 px-4 font-bold leading-relaxed text-gray-700">
                3. USB-kabel
              </p>

              <p className="mt-4 px-4 font-bold leading-relaxed text-gray-700">
                4. Gebruikershandleiding
              </p>
            </details>
            <details className="group [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
                <h2 className="font-medium">
                  Welke resolutie heeft de camera?
                </h2>

                <svg
                  className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </summary>

              <p className="mt-4 px-4 leading-relaxed text-gray-700">
                De camera maakt foto's met maximaal 44 megapixels (44 MP)
                resolutie en neemt video's op in Full HD 1080p-resolutie, zorgen
                voor beelden en opnames van hoge kwaliteit.
              </p>
            </details>
            <details className="group [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
                <h2 className="font-medium">Heb ik een geheugenkaart nodig?</h2>

                <svg
                  className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </summary>

              <p className="mt-4 px-4 leading-relaxed text-gray-700">
                Als u van plan bent extra foto's (100 - 5000) op te slaan,
                kunnen wij Wij raden u aan de micro SD-kaart van 32 GB te
                gebruiken die in de doos zit.
              </p>
            </details>

            <details className="group [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
                <h2 className="font-medium">
                  Hoe stel ik de camera in en maak ik foto's?
                </h2>

                <svg
                  className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </summary>

              <p className="mt-4 px-4 leading-relaxed text-gray-700">
                1. Plaats de oplaadbare Li-Ion-batterij in het compartiment
                voorzien op de camera. 2. Sluit het batterijcompartiment veilig.
                3. Schakel de camera in door op de aan/uit-knop te drukken. 4.
                Kadreer uw beeld met behulp van de zoeker of het LCD-scherm. 5.
                Aanpassen instellingen zoals zoom en focus met behulp van de
                bedieningsknoppen. 6. Druk op de ontspanknop half in om scherp
                te stellen en druk vervolgens volledig in om een ​​opname te
                maken foto. 7. Controleer uw foto op het LCD-scherm of in de
                zoeker.
              </p>
            </details>

            <details className="group [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray-50 p-4 text-gray-900">
                <h2 className="font-medium">Nog vragen?</h2>

                <svg
                  className="h-5 w-5 shrink-0 transition duration-300 group-open:-rotate-180"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </summary>

              <p className="mt-4 px-4 leading-relaxed text-gray-700">
                Heeft u nog vragen over de Amsterzoom digitaal? camera, neem dan
                eenvoudig contact met ons op via info@amsterzoom.nl
              </p>
            </details>
          </div>
        </div>
      </section>

      <section class="bg-gray-50 py-12 sm:py-16 lg:py-20">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div class="flex flex-col items-center">
            <div class="text-center">
              <h2 class="mt-4 text-3xl font-bold text-blue-900 sm:text-4xl xl:text-5xl">
                Kijk eens naar wat onze klanten zeggen
              </h2>
            </div>

            <div class="relative mt-10 md:order-2 md:mt-24">
              <div class="absolute -inset-x-1 inset-y-16 md:-inset-x-2 md:-inset-y-6">
                <div class="mx-auto h-full w-full max-w-5xl rounded-3xl opacity-30 blur-lg filter"></div>
              </div>

              <div class="relative mx-auto grid max-w-lg grid-cols-1 gap-6 md:max-w-none md:grid-cols-3 lg:gap-10">
                <div class="flex flex-col overflow-hidden rounded-xl border shadow-sm">
                  <img src="https://images.loox.io/uploads/2024/7/13/CLvIsK5C9Q.jpg" />
                  <div class="flex flex-1  flex-col justify-between bg-white p-6 lg:px-7 lg:py-8">
                    <div class="flex-1">
                      <div class="flex items-center">
                        <svg
                          class="h-5 w-5 text-orange-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          class="h-5 w-5 text-orange-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          class="h-5 w-5 text-orange-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          class="h-5 w-5 text-orange-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          class="h-5 w-5 text-orange-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      </div>

                      <blockquote class="mt-8 flex-1">
                        <p class="font-[400] text-xl italic text-blue-900">
                          “De camera is esthetisch mooi, maar neemt ook mooie
                          foto's!”
                        </p>
                      </blockquote>
                    </div>

                    <div class="mt-8 flex items-center">
                      <div class="ml-4">
                        <p class="text-base items-center flex font-bold text-blue-900">
                          Sophie U.{" "}
                          <span>
                            <MdVerified />
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col overflow-hidden rounded-xl border shadow-sm">
                  <img src="https://images.loox.io/uploads/2024/7/13/EYkf7ABSC.jpg" />
                  <div class="flex flex-1 flex-col justify-between bg-white p-6 lg:px-7 lg:py-8">
                    <div class="flex-1">
                      <div class="flex items-center">
                        <svg
                          class="h-5 w-5 text-orange-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          class="h-5 w-5 text-orange-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          class="h-5 w-5 text-orange-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          class="h-5 w-5 text-orange-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          class="h-5 w-5 text-orange-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      </div>

                      <blockquote class="mt-8 flex-1">
                        <p class="font-[400] text-xl italic text-blue-900">
                          “Geweldig formaat en geweldig product. Mijn dochter
                          heeft hem gebruikt de tienerpodcast op vakantie”
                        </p>
                      </blockquote>
                    </div>

                    <div class="mt-8 flex items-center">
                      <div class="ml-4">
                        <p class="text-base flex items-center font-bold text-blue-900">
                          Eva T.{" "}
                          <span>
                            <MdVerified />
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col overflow-hidden rounded-xl border shadow-sm">
                  <img src="https://images.loox.io/uploads/2024/7/13/GsN9JSrpv.jpg" />
                  <div class="flex flex-1 flex-col justify-between bg-white p-6 lg:px-7 lg:py-8">
                    <div class="flex-1">
                      <div class="flex items-center">
                        <svg
                          class="h-5 w-5 text-orange-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          class="h-5 w-5 text-orange-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          class="h-5 w-5 text-orange-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          class="h-5 w-5 text-orange-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          class="h-5 w-5 text-orange-500"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      </div>

                      <blockquote class="mt-8 flex-1">
                        <p class="font-[400] text-xl italic text-blue-900">
                          “Ik was op zoek naar een eenvoudige camera voor
                          vintage foto's en deze camera doet het goed.”
                        </p>
                      </blockquote>
                    </div>

                    <div class="mt-8 flex items-center">
                      <div class="ml-4">
                        <p class="text-base flex items-center font-bold text-blue-900">
                          Mia A.{" "}
                          <span>
                            <MdVerified />
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
