import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/newLogo1.png";
import { useAuth } from "../hooks/useAuth";

import { BsInstagram } from "react-icons/bs";
import { BsTiktok, BsSnapchat, BsDiscord } from "react-icons/bs";
import { IoLocationOutline } from "react-icons/io5";
import { FaSchool, FaDollarSign } from "react-icons/fa";
import { MdCandlestickChart, MdManageAccounts } from "react-icons/md";

// <div className="flex flex-col items-center justify-center">
//   <a
//     href="#"
//     className="inline-block rounded-xl bg-black px-8 py-4 text-center font-semibold text-white [box-shadow:rgb(19,_83,_254)_6px_6px]"
//   >
//     Get Started
//   </a>
// </div>;

const productImages = {
  large1:
    "https://nostalgixdigital.com/cdn/shop/files/DesignohneTitel_7.png?v=1720888940&width=800",
  large2:
    "https://nostalgixdigital.com/cdn/shop/files/DesignohneTitel_5.png?v=1720380654&width=800",
  large3:
    "https://nostalgixdigital.com/cdn/shop/files/Bildschirm_foto_2024-06-24_um_19.42.20_cf00a61e-b1ca-45ea-975f-3118b0099de7.png?v=1719518726&width=600",
  large4:
    "https://nostalgixdigital.com/cdn/shop/files/DesignohneTitel_8.png?v=1720888939&width=800",
};

export default function Home() {
  const { config } = useAuth();
  const navigate = useNavigate()

  const [currentImage, setCurrentImage] = React.useState(productImages.large1)

  const footerNavs = [
    {
      href: "/privacy-policy",
      name: "Privacy Policy",
    },
    {
      href: "/terms",
      name: "Terms",
    },
    {
      href: "javascript:void()",
      name: "Support",
    },
  ];

  return (
    <div style={{ overflowX: "hidden" }}>
      <section class="bg-center bg-cover bg-no-repeat bg-[url('https://nostalgixdigital.com/cdn/shop/files/6b971e32f84604aa593b0c339acb6552.jpg?v=1720351703&width=700')] bg-gray-700 bg-blend-multiply">
        <div class="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-56">
          <h1 class="mb-4 text-2xl font-extrabold tracking-tight leading-none text-white md:text-[17px] lg:text-[17px]">
            ★ ★ ★ ★ ★ 22.500 tevreden klanten
          </h1>
          <p class="mb-8 text-[24px] font-normal text-gray-300 lg:text-4xl sm:px-16 lg:px-48">
            Creëer herinneringen die je niet snel zult vergeten
          </p>
          <div class="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
            <Link
              to="/product/camera"
              className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-black rounded-lg bg-white focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
            >
              Winkel nu
              <svg
                aria-hidden="true"
                class="ml-2 -mr-1 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
        </div>
      </section>
      {/* Section Features  */}
      <section>
        {/* Container */}
        <div className="mx-auto max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
          {/* Heading Div */}
          <div className="mx-auto w-full max-w-3xl text-center">
            <h2 className="text-3xl font-semibold md:text-5xl">
              mee gevangen📸
              <span className="bg-black bg-cover bg-center bg-no-repeat px-4 text-white">
                #amsterzoom
              </span>
            </h2>
          </div>
        </div>

        <div class="grid grid-cols-2 gap-4 md:grid-cols-4">
          <div class="grid gap-4">
            <div>
              <img
                class="h-auto max-w-full rounded-lg object-cover object-center"
                src="https://nostalgixdigital.com/cdn/shop/files/ee6484128d2a9f475c12baeb146ae121.jpg?v=1719176925&width=400"
                alt="gallery-photo"
              />
            </div>
          </div>
          <div class="grid gap-4">
            <div>
              <img
                class="h-auto max-w-full rounded-lg object-cover object-center"
                src="https://nostalgixdigital.com/cdn/shop/files/861adc0eaf4bfda0afe9962428e2e7a9.jpg?v=1719176926&width=500"
                alt="gallery-photo"
              />
            </div>
          </div>
          <div class="grid gap-4">
            <div>
              <img
                class="h-auto max-w-full rounded-lg object-cover object-center"
                src="https://nostalgixdigital.com/cdn/shop/files/2279a3616908728bfb9060181aae836b.jpg?v=1719176925&width=500"
                alt="gallery-photo"
              />
            </div>
          </div>
          <div class="grid gap-4">
            <div>
              <img
                class="h-auto max-w-full rounded-lg object-cover object-center"
                src="https://nostalgixdigital.com/cdn/shop/files/4b1387b4624c36bc347c86b7071999c9.jpg?v=1719176925&width=500"
                alt="gallery-photo"
              />
            </div>
          </div>
        </div>
      </section>

      <section class="py-12 sm:py-16">
        <div class="container mx-auto px-4">
          <nav class="flex">
            <ol role="list" class="flex items-center">
              <li class="text-left">
                <div class="-m-1">
                  <a
                    href="#"
                    class="rounded-md p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800"
                  >
                    {" "}
                  </a>
                </div>
              </li>

              <li class="text-left">
                <div class="flex items-center">
                  <span class="mx-2 text-gray-400"></span>
                  <div class="-m-1">
                    <a
                      href="#"
                      class="rounded-md p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800"
                    >
                      {" "}
                    </a>
                  </div>
                </div>
              </li>

              <li class="text-left">
                <div class="flex items-center">
                  <span class="mx-2 text-gray-400"></span>
                  <div class="-m-1">
                    <a
                      href="#"
                      class="rounded-md p-1 text-sm font-medium text-gray-600 focus:text-gray-900 focus:shadow hover:text-gray-800"
                      aria-current="page"
                    >
                      {" "}
                    </a>
                  </div>
                </div>
              </li>
            </ol>
          </nav>

          <div class="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16">
            <div class="lg:col-span-3 lg:row-end-1">
              <div class="lg:flex lg:items-start">
                <div class="lg:order-2 lg:ml-5">
                  <div class="max-w-xl overflow-hidden rounded-lg">
                    <img
                      class="h-full w-full max-w-full object-cover"
                      src={currentImage}
                      alt=""
                    />
                  </div>
                </div>

                <div class="mt-2 w-full lg:order-1 lg:w-32 lg:flex-shrink-0">
                  <div class="flex flex-row gap-3 items-center justify-center md:items-start lg:flex-col">
                    <button
                      type="button"
                      class={`flex-0 aspect-square mb-3 h-20 overflow-hidden rounded-lg ${
                        currentImage === productImages.large1 && "border-2"
                      } border-gray-900 text-center`}
                    >
                      <img
                        class="h-full w-full object-cover"
                        onClick={() => setCurrentImage(productImages.large1)}
                        src="https://nostalgixdigital.com/cdn/shop/files/DesignohneTitel_7.png?v=1720888940&width=128"
                        alt=""
                      />
                    </button>
                    <button
                      type="button"
                      class={`flex-0 aspect-square mb-3 h-20 overflow-hidden ${
                        currentImage === productImages.large2 && "border-2"
                      } rounded-lg border-gray-900 text-center`}
                    >
                      <img
                        class="h-full w-full object-cover"
                        onClick={() => setCurrentImage(productImages.large2)}
                        src="https://nostalgixdigital.com/cdn/shop/files/DesignohneTitel_5.png?v=1720380654&width=128"
                        alt=""
                      />
                    </button>
                    <button
                      type="button"
                      onClick={() => setCurrentImage(productImages.large3)}
                      class={`flex-0 aspect-square border-gray-900 mb-3 h-20 overflow-hidden rounded-lg ${
                        currentImage === productImages.large3 && "border-2"
                      } text-center`}
                    >
                      <img
                        class="h-full w-full object-cover"
                        src="https://nostalgixdigital.com/cdn/shop/files/Bildschirm_foto_2024-06-24_um_19.42.20_cf00a61e-b1ca-45ea-975f-3118b0099de7.png?v=1719518726&width=128"
                        alt=""
                      />
                    </button>
                    <button
                      type="button"
                      onClick={() => setCurrentImage(productImages.large4)}
                      class={`flex-0 aspect-square border-gray-900 mb-3 h-20 overflow-hidden rounded-lg ${
                        currentImage === productImages.large4 && "border-2"
                      }  text-center`}
                    >
                      <img
                        class="h-full w-full object-cover"
                        src="https://nostalgixdigital.com/cdn/shop/files/DesignohneTitel_8.png?v=1720888939&width=800"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="lg:col-span-2 lg:row-span-2 lg:row-end-2">
              <h1 class="text-center md:text-left text-2xl font-bold text-gray-900 sm:text-3xl">
                AmsterZoom™ Pro digitale camera
              </h1>

              <div class="mt-10 flex flex-col items-center justify-between space-y-4 border-t border-b py-4 sm:flex-row sm:space-y-0">
                <div class="flex items-end">
                  <h1 class="font-bold"></h1>
                  <span class="text-green-500 font-bold text-[23px]">
                    €{config.product_fee_eur}
                  </span>
                  <strike class="pl-[12px] font-bold text-[23px]">
                    €{config.previous_price}
                  </strike>
                  <span class="bg-green-600 text-white font-medium me-2 ml-[20px] mb-[6px] px-2.5 rounded dark:bg-green-900 dark:text-green-300">
                    redden €{config.previous_price - config.product_fee_eur}
                  </span>
                </div>
              </div>

              <div
                className="flex gap-3 justify-center md:justify-start mt-[15px] mb-[20px]"
                style={{ alignItems: "center" }}
              >
                <div class="flex">
                  <div class="relative inline-flex">
                    <div class="w-5 h-5 bg-green-500 rounded-full"></div>
                    <div class="w-5 h-5 bg-green-500 rounded-full absolute top-0 left-0 animate-ping"></div>
                    <div class="w-5 h-5 bg-green-500 rounded-full absolute top-0 left-0 animate-pulse"></div>
                  </div>
                </div>
                <p className="text-green-500 text-[19px]">
                  Beperkte voorraden beschikbaar! Haast je!
                </p>
              </div>

              <button
                type="button"
                onClick={(e) => navigate("/product/camera")}
                class="inline-flex mt-[20px] w-full items-center justify-center rounded-md border-2 border-transparent bg-black bg-none px-12 py-3 text-center text-base font-bold text-white transition-all duration-200 ease-in-out focus:shadow"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="shrink-0 mr-3 h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                  />
                </svg>
                koop nu
              </button>

              <ul class="mt-8 space-y-2"></ul>
            </div>
          </div>
        </div>
      </section>

      <section class="dark:bg-gray-900 lg:mt-[-200px]">
        <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <div class="mt-4 md:mt-0">
            <h2 class="mb-4 text-4xl text-center tracking-tight font-extrabold text-gray-900 dark:text-white">
              Tijdreizen in beeld
            </h2>
            <p class="mb-6 font-light text-center text-gray-500 md:text-lg dark:text-gray-400">
              Vergeet de momentopnamen en begin met het creëren van blijvende
              herinneringen.
            </p>
          </div>
          <img
            class="w-full dark:hidden rounded-lg"
            src="https://nostalgixdigital.com/cdn/shop/files/4b1387b4624c36bc347c86b7071999c9.jpg?v=1719176925&width=500"
            alt=""
          />
        </div>
      </section>

      <div class="relative mx-auto w-full py-16 px-5 font-sans text-gray-800 sm:px-20 md:max-w-screen-lg lg:py-24">
        <h2 class="mb-5 text-center font-sans text-4xl sm:text-5xl font-bold">
          FAQs
        </h2>
        <ul class="space-y-4">
          <li class="text-left">
            <label
              for="accordion-2"
              class="relative flex flex-col rounded-md border border-gray-100 shadow-md"
            >
              <input class="peer hidden" type="checkbox" id="accordion-2" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="absolute right-0 top-4 ml-auto mr-5 h-4 text-gray-500 transition peer-checked:rotate-180"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
              <div class="relative ml-4 cursor-pointer select-none items-center py-4 pr-12">
                <h3 class="text-sm text-gray-600 lg:text-base">
                  Naar welke landen verzenden wij onze producten?
                </h3>
              </div>
              <div class="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-96">
                <div class="p-5">
                  <p class="text-sm">
                    Wij verzenden onze producten uitsluitend naar Nederland
                  </p>
                </div>
              </div>
            </label>
          </li>

          <li class="text-left">
            <label
              for="accordion-3"
              class="relative flex flex-col rounded-md border border-gray-100 shadow-md"
            >
              <input class="peer hidden" type="checkbox" id="accordion-3" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="absolute right-0 top-4 ml-auto mr-5 h-4 text-gray-500 transition peer-checked:rotate-180"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
              <div class="relative ml-4 cursor-pointer select-none items-center py-4 pr-12">
                <h3 class="text-sm text-gray-600 lg:text-base">
                  Verzendtijd en verwerking
                </h3>
              </div>
              <div class="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-96">
                <div class="p-5">
                  <p class="text-sm">
                    We bieden momenteel een vast tarief aan: gratis verzending
                    voor iedereen bestellingen. Afhankelijk van het ordervolume
                    duurt de orderverwerking 1-3 dagen vóór verzending. Zodra uw
                    artikel is geweest verzonden, de geschatte levertijd is:
                    7-12 werken dagen
                  </p>
                </div>
              </div>
            </label>
          </li>

          <li class="text-left">
            <label
              for="accordion-4"
              class="relative flex flex-col rounded-md border border-gray-100 shadow-md"
            >
              <input class="peer hidden" type="checkbox" id="accordion-4" />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="absolute right-0 top-4 ml-auto mr-5 h-4 text-gray-500 transition peer-checked:rotate-180"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
              <div class="relative ml-4 cursor-pointer select-none items-center py-4 pr-12">
                <h3 class="text-sm text-gray-600 lg:text-base">
                  Heeft u nog vragen?
                </h3>
              </div>
              <div class="max-h-0 overflow-hidden transition-all duration-500 peer-checked:max-h-96">
                <div class="p-5">
                  <p class="text-sm">
                    Als u meer informatie nodig heeft of specifieke vragen heeft
                    over onze producten, aarzel niet om contact met ons op te
                    nemen via
                    <b> info@amsterzoom.nl</b>
                  </p>
                </div>
              </div>
            </label>
          </li>
        </ul>
      </div>
    </div>
  );
}
