import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Logo from "../assets/newLogo1.png";
import { MdDashboard } from "react-icons/md";
import { FaCreditCard } from "react-icons/fa";
import { MdAccountCircle } from "react-icons/md";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { IoLogOutOutline } from "react-icons/io5";
import { IoClose } from "react-icons/io5";


export default function MobileMenu() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  const location = useLocation();

  const renderNav = (targetUrl, text, Icon) => {
    if (location.pathname !== targetUrl) {
      return (
        <li>
          <Link
            to={targetUrl}
            className="flex font-medium w-full text-gray-500 hover:text-gray-200 py-2 justify-center"
            onClick={() => setMobileNavOpen(false)}
          >
            {text}
          </Link>
        </li>
      );
    } else {
      return (
        <li>
          <Link
            to={targetUrl}
            style={{ borderBottom: "1px solid black" }}
            className="font-medium w-full inline-flex items-center justify-center border border-transparent px-4 py-2 my-2 rounded-sm text-black bg-transparent transition duration-150 ease-in-out"
            onClick={() => setMobileNavOpen(false)}
          >
            {text}
          </Link>
        </li>
      );
    }
  };

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (
        !mobileNavOpen ||
        mobileNav.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [mobileNavOpen]);

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [mobileNavOpen]);

  return (
    <div className="md:hidden p-[7px] px-[10px]">
      {/* Hamburger button */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Link to="/">
          <img src={Logo} className="w-28 h-auto" />
        </Link>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 20,
          }}
        >
          {user && (
            <button
              type="button"
              onClick={() => {
                if (user) {
                  navigate("/dashboard");
                } else {
                  navigate("/login ");
                }
              }}
              className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-xl bg-[#82310c] text-white transition disabled:opacity-50 disabled:pointer-events-none focus:outline-none"
            >
              {user ? "Dashboard" : "Login"}
            </button>
          )}
          <button
            className={`hamburger ${mobileNavOpen && "active"}`}
            aria-controls="mobile-nav"
            aria-expanded={mobileNavOpen}
            onClick={() => setMobileNavOpen(!mobileNavOpen)}
          >
            <span className="sr-only">Menu</span>
            {mobileNavOpen ? (
              <IoClose color="gray" size={35} />
            ) : (
              <svg
                className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="4" width="24" height="2" rx="1" />
                <rect y="11" width="24" height="2" rx="1" />
                <rect y="18" width="24" height="2" rx="1" />
              </svg>
            )}
          </button>
        </div>
      </div>
      {/*Mobile navigation */}
      <nav
        id="mobile-nav"
        ref={mobileNav}
        className="w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out"
        style={
          mobileNavOpen
            ? { maxHeight: mobileNav.current?.scrollHeight, opacity: 1 }
            : { maxHeight: 0, opacity: 0.8 }
        }
      >
        <ul className="px-4 py-2" style={{ backgroundColor: "#ffffff" }}>
          {renderNav("/", "Home", MdDashboard)}
          {renderNav(
            "/product/camera",
            " AmsterZoom Pro 🇳🇱🔥",
            MdAccountCircle
          )}
          {renderNav("/tracking", "Volgen", MdAccountCircle)}
          {renderNav("/contact-us", "Contact", MdAccountCircle)}
        </ul>
      </nav>
    </div>
  );
}
